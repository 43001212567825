<template>
  <div class="classification">
    <div class="classificinner">
      <div class="classificationTitle">
        <img class="classificationicon" src="@/assets/sorting/classification.png"> 分类筛选
      </div>
      <!--          <a-menu mode="inline" :open-keys="openKeys" style="width: 100%"  class="radioForm">-->
      <!--               <template v-for="item in data">-->
      <!--                       <a-menu-item v-if="!item.children" :key="item.value">-->
      <!--                         <span class="namecassify" @click="changeRadio(item,item.value)" :style="{color:checkedCate==''?'#2140A4':'#000'}"> {{item.label}}</span>-->

      <!--                       </a-menu-item>-->
      <!--                       <a-sub-menu :key="item.value" v-else @titleClick="onOpenChange($event,item.value,item.children)">-->
      <!--                         <span slot="title" class="namecassify" :style="{color:checkedCateFa==item.value?'#2140A4':'#919191'}">-->
      <!--&lt;!&ndash;                          {{item.label}}&ndash;&gt;-->

      <!--                           {{item.label}}-->
      <!--                          </span>-->
      <!--                         <a-menu-item :key="itemc.value" v-for="(itemc,indexc) in item.children">-->
      <!--                            <a-radio class="classifychild" :checked="checkedCate==itemc.value" @click="changeRadio(itemc,item.value)">{{itemc.label}}</a-radio>-->
      <!--                         </a-menu-item>-->
      <!--                       </a-sub-menu>-->
      <!--                </template>-->
      <!--             </a-menu>-->
      <div class="menus-view">
        <div class="menu-group" v-for="item in data">
          <div class="menu-item menu-header-item"
               :class="{'open-header': openValue == item.value || selectedValue == item.value, }"
               @click="item.children ? menuOpen(item) : selectedValueChange($event, item)">
            <div class="item-radio-container" @click="selectedValueChange($event, item)">
              <div class="item-radio" :class="{'radio-selected': selectedValue == item.value}"></div>
            </div>
            {{item.label}}
            <i v-if="item.children" class="change-arrow" :class="{'header-open-arrow': openValue === item.value}"></i>
          </div>
          <div class="sub-menus"
               :style="{height: openValue === item.value ? item.children.length * 70 + item.children.length * 5 + 5 + 'px' : '0'}">
            <div
                    @click="selectedValueChange($event, itemc)"

                    class="menu-item"
                    :class="{'menu-item-selected': selectedValue == itemc.value}"
                    v-for="itemc in item.children">
              <div class="item-radio-container">
                <div class="item-radio" :class="{'radio-selected': selectedValue == itemc.value}"></div>
              </div>
              {{itemc.label}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: '',
    props: {
      data: []
    },
    data() {
      return {
        value: '',
        checkedCate: '',
        checkedCateFa: '',
        openKeys: [''],

        openValue: null,
        selectedValue: null
      }
    },
    methods: {
      changeRadio(e, key) {
        this.checkedCate = e.value;
        this.checkedCateFa = key
        this.$emit('change', e);
      },
      selectedValueChange(e, item) {
        e.stopPropagation()
        this.selectedValue = item.value;
        console.log(item)
        this.$emit('change', {value: this.getItemsAllId(item), label: item.label});

      },
      getItemsAllId(item) {
        let ids = []
        if (item.children && item.children.length > 0) {
          item.children.forEach(v => {
            ids.push(this.getItemsAllId(v))
          })
        } else {

          return item.value;
        }
        return ids.join(',');
      },
      menuOpen(item) {
        if (this.openValue === item.value) {
          this.openValue = null
        } else {
          this.openValue = item.value
        }

      },
      onOpenChange(e, key, child) {
        var data = []
        data.push(key)
        if (this.openKeys.length > 0 && data.toString() == this.openKeys.toString()) {
          this.openKeys = [];
        } else {
          this.openKeys = data;
          if (child.length > 0) {
            this.checkedCate = child[0].value;
            console.log(child[0].value)
            this.checkedCateFa = key
            this.$emit('change', child[0]);
          }

        }
      }
    },
  }
</script>
<style scoped lang="less">
  .namecassify {
    font-size: 23px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .classifychild {
    font-size: 23px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #919191;
    line-height: 32px;
    letter-spacing: 1px;

  }

  .classificationTitle {
    height: 32px;
    font-size: 23px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 32px;
    letter-spacing: 1px;
    padding: 23px 25px;
    padding-bottom: 40px;
  }

  .radioForm {
    margin-top: 20px;
  }

  .classification {
    background: #fff;
    width: 100%;
    height: 640px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 2px 11px 0px rgba(177, 188, 199, 0.15);

  }

  .classificinner {
    position: absolute;
    left: 0;
    top: 0;
    right: -17px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }

</style>
<style>
  .ant-menu-item-selected, .ant-menu-submenu-selected {
    color: #2140A4;
  }

  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: #2140A4;
  }

  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
  }

  .ant-menu-submenu-open .classifydather {
    color: #000;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #fff;
  }

  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #fff;
  }

  .ant-menu-submenu > .ant-menu {
    background: #F1F2F2;
    box-shadow: 0px 2px 4px 0px #F1F2F2;
  }

  .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 0px !important;
  }

  .ant-menu-submenu-title .ant-menu-submenu-arrow {
    color: #f00 !important;
    font-size: 40px;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 70px !important;
    line-height: 70px !important;
    padding-left: 28px !important;
    display: flex;
    align-items: center;
  }

  .ant-menu-submenu-title {
    height: 70px !important;
    line-height: 70px !important;
  }

  .radioForm .ant-radio-inner {
    width: 23px;
    height: 23px;
  }

  .radioForm .ant-radio-inner::after {
    border: 1px solid #B2B2B2;
    width: 15px;
    height: 15px;
  }

  .radioForm .ant-radio-inner {
    border-color: #B2B2B2;
  }

  .radioForm .ant-radio-checked .ant-radio-inner {
    border: 1px solid #B2B2B2;
  }

  .radioForm .ant-radio-inner::after {
    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
  }

  .radioForm .ant-radio-wrapper span {
    font-size: 23px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 1px;
  }

  .radioForm .ant-radio-wrapper-checked span {
    color: #000;
  }


  .menus-view {
    width: 100%;
  }

  .menu-group {
    width: 100%;
  }


  .item-radio-container {
    padding: 0 15px;
  }

  .item-radio {
    width: 23px;
    height: 23px;
    border-radius: 23px;
    border: 1px solid #B2B2B2;
    position: relative;
  }

  .radio-selected:after {
    background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
    border: 1px solid #B2B2B2;
    width: 15px;
    height: 15px;
    transform: scale(1);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

    position: absolute;
    top: 3px;
    left: 3px;
    display: table;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    content: ' ';
  }

  .menu-item {
    display: flex;
    align-items: center;

    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 1px;
    color: #919191;
    font-size: 23px;
    position: relative;
    height: 70px;
    width: 100%;
    margin-top: 5px;
  }

  .menu-item:last-child {
    margin-bottom: 5px;
  }

  .menu-header-item {
    margin: 0;
  }

  .menu-item-selected {
    color: #000;
    background-color: #fff;
  }

  .open-header {
    color: rgb(33, 64, 164);
  }

  .change-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: #f00 !important;
    font-size: 40px;
  }

  .change-arrow:before {
    transform: rotate(-45deg) translateX(2px);
    position: absolute;
    width: 6px;
    height: 1.5px;
    background: #fff;
    background: rgba(0, 0, 0, 0.65) \9;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
    background-image: none \9;
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }

  .change-arrow:after {
    transform: rotate(45deg) translateX(-2px);
    position: absolute;
    width: 6px;
    height: 1.5px;
    background: #fff;
    background: rgba(0, 0, 0, 0.65) \9;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
    background-image: none \9;
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }

  .sub-menus {
    background: #F1F2F2;
    box-shadow: 0px 2px 4px 0px #f1f2f2;
    cursor: initial;
    transition: all 0.3s linear;
    overflow: hidden;

  }

  .header-open-arrow:before {
    transform: rotate(45deg) translateX(2px);
  }

  .header-open-arrow:after {
    transform: rotate(-45deg) translateX(-2px);
  }
</style>
