<template>
  <div class="sortingformbox">
      <a-config-provider :locale="locale">
      <a-form-model layout="inline" class="sortingForm" style="width:100%">
          <div class="rowsortingform">
              <div>
                  <a-form-model-item class="itemFormItem">
                     <a-button class="back-btn bgBlue" size="large" type="primary" style="padding:0px 28px;" @click="go_back">
                       <a-icon type="arrow-left" /> 返回
                     </a-button>
                  </a-form-model-item>
                  <a-form-model-item  class="itemFormItem">
                      <a-date-picker :locale="locale" @change="onChange" placeholder="2021-08-10" style="width:190px" :defaultValue="moment(dataObj.send_time, 'YYYY-MM-DD')"/>
                  </a-form-model-item>
                  <a-form-model-item  class="itemFormItem">
                      <a-select placeholder="分拣状态" v-model="dataObj.sorting_status"  class="form-input" style="width:190px" @change="changeStatus">
                          <a-select-option class="option-class" value="">
                              全部
                          </a-select-option>
                          <a-select-option v-for="(item,index) in sortStatusList" :key="index" class="option-class" :value="item.id">
                              {{item.name}}
                          </a-select-option>
                             
                      </a-select>
                  </a-form-model-item>
              </div>
              <div>
                  <a-form-model-item  class="itemFormItem">
                      <a-input-search placeholder="输入商品名称" @search="changedata" height="90" style="width:195px" v-model="dataObj.name" @change="changedata"/>
                  </a-form-model-item>
                  <a-form-model-item class="itemFormItem">
                      <a-button class="query-btn bgBlue" size="large" type="primary" style="padding:0px 28px;" @click="searchdata">
                          查询
                      </a-button>
                  </a-form-model-item>

                  <a-form-model-item class="itemFormItem" >
                      <a-button class="query-btn bgBlue" size="large" type="primary" style="padding:0px 28px;" @click="refresh">
                          刷新
                      </a-button>
                  </a-form-model-item>
              </div>
            
          </div>
         
      </a-form-model>
      </a-config-provider>
  </div>
</template>

<script>
import { PrintAccount } from '@/assets/js/doPrint'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
 import moment from 'moment';
export default {
  name: 'sortingform',
  props: {
    msg: '',
    data:{},
    sortStatusList:[]
  },
  data() {
    return {
     locale:zhCN,
     moment,
     dataObj:{},
     sortingstatus:undefined,
    }
  },
  created(){
      this.dataObj = this.data;
  },
  watch:{
      data: function (a, b) {
        this.dataObj = a
      },
  },
  methods: {
      printUrl(){
          let url='http://testcxcs.znsyun.com/merchant/sorting/print/tag?printCount=1&sorting_order=10234&merchantName=%E9%85%8D%E9%80%81%E5%95%86%EF%BC%88Test%EF%BC%89&clientName=%E9%81%B5%E4%B9%89%E5%B8%82%E4%BD%93%E8%82%B2%E8%BF%90%E5%8A%A8%E5%AD%A6%E6%A0%A1%EF%BC%8822%EF%BC%89&lineName=%E7%BA%BF%E8%B7%AF9&productName=%E8%8F%9C%E7%B1%BD%E6%B2%B9&sortingSum=1&unit=%E6%A1%B6&printDate=2021-08-18&sorting_sum=1&sorting_residue=6&sku_name=&type=tag'
          PrintAccount('分拣标签打印',url,'print')
      },
      changeStatus(e){
        this.dataObj.sorting_status=e;
        this.$emit('search',this.dataObj)
      },
      go_back(){
          this.$router.back()
      },
      onChange(e,datestring){
          this.dataObj.send_time=datestring;
          this.$emit('search',this.dataObj)
      },
      searchdata(){
          this.$emit('search',this.dataObj)
      },
      changedata(e){
          this.$emit('change',this.dataObj)
      },
      refresh() {
          this.$emit('refresh')
      }
  }
}
</script>
<style scoped lang="less">
    /deep/ .ant-form-inline .ant-form-item{
         margin-right:15px;
     }
    .sortingForm /deep/ .ant-input,/deep/ .ant-select-selection__rendered{
         height:80px !important; 
         font-size: 20px;
         font-family: PingFangSC-Semibold, PingFang SC;
         font-weight: 600;
         color: #000;
     }
    .form-input {
        width: 100%;
        height:80px;
        background: #FFFFFF;
        border-radius: 5px;
        border: 1px solid #C6CBD4;
    
        font-size: 23px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #1F3D9E;
    }
    .sortingForm /deep/ .ant-input-search-icon,/deep/ .ant-calendar-picker-icon{
        font-size:20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #B2B2B2;
    }
    /deep/ .ant-form-item-label > label{
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #242424;
        line-height: 80px;
    }
    /deep/ .ant-select-selection.ant-select-selection--single {
        height: 100%;
        border: none;
    }
    /deep/ .ant-select-selection__rendered {
        height: 100%;
        border: none;
        display: flex;
        align-items: center;
    }
    .filter-btn {
        width: 97px;
        height: 80px;
        border-radius: 5px;
        border: 1px solid #C6CBD4;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2140A4;
    }
    .sortingformbox{
        background: #fff;
        display: flex;
        padding:25px;
        align-items: center;
        width:100%;
    }
    .rowsortingform{
        display:flex;
        justify-content: flex-start;
            width: 100%;
    }
   .logo-top-img{
       width: 16%;
       height:80px;
       background: #FFFFFF;
       box-shadow: 0px 2px 11px 0px rgb(177 188 199 / 15%);
       text-align: center;
       display: flex;
       justify-content: center;
       align-items: center;
       .logo-img{
           width:77.5%;
           
       }
   }
   .bgBlue{
      background: linear-gradient(136deg, #2D54DC 0%, #1E3B98 100%);
      border-radius: 5px; 
      border:none;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
       height:80px;
      outline:none;
   }
   .bgDotted{
       background: #fff;
        border-radius: 5px; 
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #203EA0;
        border:none;
       height:80px;
        outline:none;
        color:#203EA0;
        border: 1px solid #C6CBD4;
   }
   .itemFormItem{
        height:80px;
        line-height: 80px;
   }
</style>
