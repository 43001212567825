<template>
  <div class="producttab">
      <div class="tagstatus" :style="{background:color}">{{data.sorting_status}}</div>
      <div class="boxcenter">
          <div class="itemsort">{{data.title}}</div>
          <div class="itemsort" style="font-size:18px">订购:<span style="color:#BC1D00">{{data.order_sum}}</span>{{data.name}}</div>
      </div>
      <!-- <div class="stockproduct" :style="{background:color}" v-if="data.outstock==1">缺</div> -->
      <div class="progresssorting" :style="{background:color}">进度: {{data.soring_rate_sum}}</div>
  </div>
</template>

<script>
export default {
  name: 'producttab',
  props: {
    data: {},
    color:''
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
    .producttab{
        width: 164px;
        height: 161px;
        display:inline-block;
        background: #FFFFFF;
        box-shadow: 0px 3px 8px 4px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        position:relative;
        overflow:hidden;
          .stockproduct{
             position: absolute;
              top:0px;
              right:0px;
              width: 38px;
              height: 38px;
              background: #BC7B00;
              border-radius:100%;
              text-align:center;
               line-height: 38px;
               font-size: 23px;
               font-family: PingFangSC-Semibold, PingFang SC;
               font-weight: 600;
               color: #FFFFFF;
          }
        .boxcenter{
            .itemsort{
                padding-left:15px;
               font-size: 20px;
               font-family: PingFangSC-Semibold, PingFang SC;
               font-weight: 600;
               color: #000000;
                line-height: 32px;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space:nowrap;
            }
        }
       .tagstatus{
           font-size: 18px;
           font-family: PingFangSC-Semibold, PingFang SC;
           font-weight: 600;
           color: #FFFFFF;
           height: 44px;
         width: 80px;
         text-align:center;
         line-height:44px;
        border-radius: 0px 0px 18px 0px
       }
       .progresssorting{
           width: 100%;
           height: 50px;
           font-size: 16px;
           font-family: PingFangSC-Semibold, PingFang SC;
           font-weight: 600;
           color: #FFFFFF;
           line-height: 50px;
           position: absolute;
            bottom: 0px;
            text-align:center;
            word-break: break-all;
       }
        
    }
   
</style>
